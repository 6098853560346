var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-row", [
        _c(
          "form",
          { attrs: { action: "/" } },
          [
            _c("van-search", {
              attrs: { placeholder: "请输入搜索关键词", "show-action": "" },
              on: { search: _vm.onSearch, cancel: _vm.onCancel },
              model: {
                value: _vm.keywords,
                callback: function($$v) {
                  _vm.keywords = $$v
                },
                expression: "keywords"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "van-row",
        { attrs: { clas: "banner" } },
        [
          _c(
            "van-swipe",
            { attrs: { autoplay: 3000 }, on: { change: _vm.onChange } },
            _vm._l(_vm.banner.ads, function(data, index) {
              return _c(
                "van-swipe-item",
                { key: index, staticStyle: { height: "auto!important" } },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: data.image_url,
                        expression: "data.image_url"
                      }
                    ],
                    staticClass: "response",
                    staticStyle: { height: "100%" },
                    on: { click: _vm.bannerClick }
                  })
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-row",
        { staticClass: "text-bold text-df bg-gray" },
        [
          _c(
            "van-col",
            {
              staticClass: "padding-left padding-tb-sm",
              attrs: { span: "12" }
            },
            [_vm._v("热销产品")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.goods.data, function(pro, index) {
        return _c(
          "van-row",
          {
            staticClass: "padding-top-sm solid-bottom",
            attrs: { type: "flex", justify: "space-between" }
          },
          [
            _c(
              "van-col",
              { attrs: { span: "16" } },
              [
                _c(
                  "van-row",
                  { attrs: { type: "flex", align: "center" } },
                  [
                    _c(
                      "van-col",
                      { staticClass: "padding-left", attrs: { span: "8" } },
                      [
                        _c("img", {
                          staticClass: "radius",
                          attrs: { src: pro.cover_image, width: "70" },
                          on: {
                            click: function($event) {
                              return _vm.goToGoodsDetail(pro)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "van-col",
                      { attrs: { offset: "1" } },
                      [
                        _c("van-row", { staticClass: "text-lg" }, [
                          _c("b", [_vm._v(_vm._s(pro.name))])
                        ]),
                        _vm._v(" "),
                        _c("van-row", { staticClass: "margin-top-xs" }, [
                          _vm._v(_vm._s(pro.alias))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "van-col",
              { attrs: { span: "8" } },
              [
                _c(
                  "van-row",
                  {
                    staticClass: "text-right padding-right",
                    staticStyle: { height: "100%" }
                  },
                  [
                    _c(
                      "van-button",
                      {
                        staticClass:
                          "border-none radius-xs text-right margin-top",
                        attrs: { type: "info", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.goToGoodsDetail(pro)
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("footer-tabbar")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }