"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _FooterTabbar = _interopRequireDefault(require("components/FooterTabbar"));

var _mixins = _interopRequireDefault(require("@/mixins/mixins"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// const { mapActions } = createNamespacedHelpers('test') // 可使用这种方式直接获得test模板
var _default = {
  mixins: [_mixins.default],
  name: 'home',
  data: function data() {
    return {
      value: 1,
      images: ['https://img.yzcdn.cn/vant/apple-1.jpg', 'https://img.yzcdn.cn/vant/apple-2.jpg'],
      iconList: ['dashboard', 'example', 'eye-open', 'eye', 'form', 'link', 'nested', 'password', 'table', 'tree', 'user', '404']
    };
  },
  components: {
    FooterTabbar: _FooterTabbar.default
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    loading: function loading(state) {
      return state['@@loading'].effects['test/onePlusAsync'];
    }
  })),
  methods: {}
};
exports.default = _default;